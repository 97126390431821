import { Link } from 'gatsby'
import React from 'react'
import Image from '~/components/atoms/Image'
import TextLink from '~/components/atoms/TextLink'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function StoryPreview({className, title, uri, featuredImage}) {
  return (
    <div className={className}>
      <Link to={uri} className="block group">
        <FadeInWhenVisible className="overflow-hidden mb-7 xl:h-[320px] lg:h-[280px] md:h-[240px] h-[220px]">
          <Image className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700" data={featuredImage?.node} />
        </FadeInWhenVisible>
        <FadeInWhenVisible>
          <h5 className="text-h5 mb-6" dangerouslySetInnerHTML={{__html: title }}/>
        </FadeInWhenVisible>
        <FadeInWhenVisible>
          <TextLink title="Read story" />
        </FadeInWhenVisible>
      </Link>
    </div>
  )
}
