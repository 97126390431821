import { graphql, useStaticQuery } from 'gatsby';

const usePartnerPreviews = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPartnerStory {
        nodes {
          ...PartnerPreview
        }
      }
    }

  `);

  return data?.allWpPartnerStory?.nodes;
};

export default usePartnerPreviews;
